import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

import Breadcrumb from "../../components/Breadcrumb"
import { CategoryMain } from "../../styles/Layout"
import {
  FeaturedImage,
  HeroContent,
  ArticleList,
  SectionTitle,
} from "../../components/Category/CategoryElements"
import {
  CardListWrapper,
  CardSection,
} from "../../components/Category/PostCardElements"
import PostCard from "../../components/Category/PostCard"
import ImgBgCard from "../../components/ui/ImgBgCard"
import { CardContent } from "../../components/ui/ImgBgCard/ImgBgElements"
import Newsletter from "../../components/Newsletter"
import Seo from "../../components/seo"

const SubcategoryPage = ({ data, location }) => {
  const {
    title,
    seoDesc,
    description,
    parentCategory,
    featuredImage,
    article,
  } = data.subCategory

  const allReviews = data.allReviews.nodes

  // Sort articles in descending order
  const sortedArticles =
    article &&
    article.sort((a, b) => {
      let da = new Date(a.publishDate)
      let db = new Date(b.publishDate)

      if (da > db) {
        return -1
      } else if (da === db) {
        return 0
      } else {
        return 1
      }
    })

  const image = getImage(featuredImage)
  const imageSrc = getSrc(featuredImage) // image source for Open Graph

  return (
    <>
      <Seo
        title={title}
        description={seoDesc}
        image={imageSrc}
        imageAlt={title}
        article={false}
      />
      <CategoryMain id="main-content">
        <FeaturedImage>
          <GatsbyImage
            image={image}
            alt={title}
            loading="eager"
            style={{
              height: "59vh",
              gridColumn: "1 / -1",
              gridRow: "1 / -1",
            }}
          />
          <Breadcrumb
            className={null}
            categoryTitle={parentCategory?.title}
            categoryLink={parentCategory?.slug}
            subCategoryTitle={data.subCategory?.title}
            subCategoryLink={`/${data.subCategory?.slug}`}
          />

          <HeroContent>
            <h1 className="bg-grad-alt">{data.subCategory?.title}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html,
              }}
            />
          </HeroContent>
        </FeaturedImage>

        {location.pathname.includes("reviews") ? (
          <>
            <SectionTitle style={{ gridColumn: "1/-1", margin: "8rem 0 0" }}>
              <span>/</span> More reviews in <span>{title}</span> Category
            </SectionTitle>
            <ArticleList>
              {allReviews.map(review => {
                return (
                  <ImgBgCard
                    key={review.id}
                    center={false}
                    title={
                      (review.featuredImage?.description &&
                        review.featuredImage.description) ||
                      review.title
                    }
                    image={review.featuredImage}
                    url={`/${review.category?.slug}/${review.subCategory?.slug}/${review.slug}/`}
                  >
                    <CardContent>
                      <div className="card-date">
                        <time dateTime={review.validDate}>
                          {review.pubDate}
                        </time>{" "}
                        | <span>{`⭐`.repeat(review.overallRating)}</span>
                        <span style={{ filter: `grayscale(100%)` }}>
                          {`⭐`.repeat(5 - Number(review.overallRating))}
                        </span>
                      </div>
                      {/* <ReviewLink to={`/${category?.slug}/${subCategory?.slug}/${slug}/`}> */}
                      <h3>{review.title}</h3>
                      <p
                        className="line-clamp"
                        style={{
                          marginBottom: "1rem",
                          paddingTop: "1rem",
                          color: "var(--grey-7)",
                        }}
                      >
                        {review.summary}
                      </p>
                      {/* </ReviewLink> */}
                    </CardContent>
                  </ImgBgCard>
                )
              })}
            </ArticleList>
          </>
        ) : (
          <CardSection>
            <SectionTitle>
              <span>/</span> Stories in <span>{title}</span> Category
            </SectionTitle>
            <CardListWrapper>
              {article &&
                sortedArticles.map(post => {
                  const {
                    id,
                    slug,
                    title,
                    publishDate,
                    validDate,
                    featuredImage,
                  } = post

                  return (
                    <PostCard
                      key={id}
                      title={title}
                      slug={`/${parentCategory?.slug}/${data.subCategory?.slug}/${slug}`}
                      pubDate={publishDate}
                      validDate={validDate}
                      img={featuredImage}
                    />
                  )
                })}
            </CardListWrapper>
          </CardSection>
        )}

        <Newsletter />
      </CategoryMain>
    </>
  )
}

export default SubcategoryPage

export const subCategoryQuery = graphql`
  query ($id: String!) {
    subCategory: contentfulCategorySubCategory(id: { eq: $id }) {
      id
      slug
      title
      seoDesc: seoDescription
      description {
        childMarkdownRemark {
          html
        }
      }
      parentCategory {
        title
        slug
      }
      featuredImage {
        description
        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.777777, quality: 80)
      }
      article {
        id: contentful_id
        title
        slug
        publishDate(formatString: "MM/DD/YYYY")
        validDate: publishDate(formatString: "YYYY-MM-DD")
        featuredImage {
          gatsbyImageData(
            quality: 50
            width: 140
            height: 140
            layout: CONSTRAINED
          )
        }
      }
    }

    allReviews: allContentfulReview(
      sort: { fields: publishDate, order: DESC }
    ) {
      nodes {
        id: contentful_id
        slug
        title
        pubDate: publishDate(formatString: "DD.MM.YYYY")
        validDate: publishDate(formatString: "YYYY-MM-DD")
        summary
        featuredImage {
          description
          gatsbyImageData(width: 300, quality: 40, layout: FULL_WIDTH)
          reviewThumb: gatsbyImageData(width: 100, quality: 30)
        }
        price
        functionality
        style
        overallRating
        category {
          slug
        }
        subCategory {
          title
          slug
        }
      }
    }
  }
`

import styled from "@emotion/styled"
import { Link } from "gatsby"

export const CardSection = styled.section`
  grid-column: full-start / full-end;
  /* margin: 0 0 8rem; */
  margin: 4rem 0 0;
  padding: 8rem 5vw 12rem;
  background-color: hsla(192, 18.5%, 89.4%, 0.16);

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CardListWrapper = styled.div`
  max-width: 144rem;
  width: 90vw;
  margin: 6rem 0 4rem;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28rem, max-content));
  grid-gap: 4rem;
`

export const CardWrapper = styled.article`
  min-height: 12rem;
  border-bottom: 1px solid var(--grey-3);
  transition: all 0.3s ease-in;

  &:hover {
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
    opacity: 0.75;
  }
`

export const CardLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`

export const CardContent = styled.div`
  flex: 0 0 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 2rem;
  margin-bottom: 0.5rem;

  time {
    color: var(--grey-7);
    font-size: 1.4rem;
  }

  h4 {
    font-variation-settings: "wght" 630, "ital" 1;
  }
`

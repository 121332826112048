import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { CardWrapper, CardLink, CardContent } from "./PostCardElements"

const PostCard = ({ slug, title, pubDate, validDate, img }) => {
  const image = getImage(img)

  return (
    <CardWrapper>
      <CardLink to={slug}>
        <GatsbyImage image={image} alt={title} />
        <CardContent>
          <time
            // @ts-ignore
            dateTime={validDate}
          >
            {pubDate}
          </time>

          <h4>{title}</h4>
        </CardContent>
      </CardLink>
    </CardWrapper>
  )
}

export default PostCard

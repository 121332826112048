import styled from "@emotion/styled"
import { Link } from "gatsby"

interface CardProps {
  alignhorz: string
  alignvert: string
  direction: string
}

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  min-height: 23rem;
  border-radius: 5px;
  overflow: hidden;

  .card-image {
    grid-area: 1 / 1;
    transition: all 0.3s ease-in-out;
  }
`

export const CardLink = styled(Link)<CardProps>`
  background: linear-gradient(
    to bottom,
    rgba(9, 9, 9, 0),
    rgba(0, 0, 0, 0.63) 45%,
    rgba(0, 0, 0, 0.73) 73%,
    rgba(39, 39, 39, 1) 100%
  );
  /* background-color: #00000085; */
  color: var(--white);
  grid-area: 1 / 1;
  padding: 1vw;
  position: relative;

  display: flex;
  flex-direction: ${props => props.direction && `column`};
  align-items: ${props => props.alignvert};
  justify-content: ${props => props.alignhorz};
`

export const CardContent = styled("div")`
  z-index: 5;

  .card-date {
    filter: brightness(0.6);
  }
`

import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { CardWrapper, CardContent, CardLink } from "./ImgBgElements"

const ImgBgCard = ({ children, title, image, url, center }) => {
  const imageData = getImage(image)

  return (
    <CardWrapper>
      <GatsbyImage className="card-image" image={imageData} alt={title} />
      <CardLink
        to={url}
        className="width"
        direction="column"
        alignhorz={(center && center) || "flex-end"}
        alignvert="center"
      >
        <CardContent>{children}</CardContent>
      </CardLink>
    </CardWrapper>
  )
}

export default ImgBgCard
